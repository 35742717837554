import React from 'react'

const Hero = () => {
  return (
    <>
    <div className='w-full h-[calc(100vh-96px)] sm:h-[calc(100vh-160px)] flex justify-center bg-background_light_red'>
        <img className="hidden sm:block object-contain sm:max-w-4xl 2xl:max-w-7xl" src="assets/hero-banner.webp" alt="ipek-yucesoy-hero-banner" />
      <img className="object-contain h-full sm:hidden" src="assets/hero-banner-mobil.webp" alt="ipek-yucesoy-hero-banner" />
    </div>
    </>
  )
}

export default Hero