import React, {useEffect, useState} from 'react'

//components
import ChangePageTitle from '../components/change-page-title/ChangeTitle'
import PageLayout from '../components/page-layout'
import Card from '../components/card/Card'
import SplashScreen from '../components/splash-screen/SplashScreen'

//services
import getWorksApi from "../services/get-works"

const Works = () => {
    const [works, setWorks] = useState()
    const [loading, setLoading] = useState(true) // loading status for splash screen


  const getWorks = async () => {
    const works = await getWorksApi()
        return works
  }

  useEffect(() => {
    getWorks()
      .then(res => {
        setWorks(res)
        setLoading(false)
      })
      .catch(err => {
        console.error("Failed to fetch works:", err)
        setLoading(false)
      })
  }, [])

  return (
    <PageLayout>
      {/* splash screen */}
      {loading ? (
        <SplashScreen />
      ) : (
        <>
          {/* page content */}
          <ChangePageTitle pageTitle="Works" />
          {/* product card container */}
          <div
            className="grid grid-cols-2 md:grid-cols-4 w-full min-h-[calc(100vh-96px)] sm:min-h-[calc(100vh-160px)] grid-flow-row gap-2 md:gap-4 px-2 py-4 sm:py-8 text-center">
            {works?.map((work) => {
              return <Card key={work?.id} item={work} />
            })}
          </div>
        </>
      )}
    </PageLayout>
  )
}

export default Works
