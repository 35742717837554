import React, { useEffect, useState } from 'react'

//components
import ChangePageTitle from '../components/change-page-title/ChangeTitle'
import PageLayout from '../components/page-layout'
import Card from '../components/card/Card'
import SplashScreen from '../components/splash-screen/SplashScreen'

//services
import getProjectsApi from '../services/get-projects'

const Projects = () => {
  const [projects, setProjects] = useState()
  const [loading, setLoading] = useState(true) // loading status for splash screen

  const getProjects = async () => {
    const projects = await getProjectsApi()
    return projects
  }

  useEffect(() => {
    getProjects()
      .then(res => {
        setProjects(res)
        setLoading(false)
      })
      .catch(err => {
        console.error('Failed to fetch works:', err)
        setLoading(false)
      })
  }, [])

  return (
    <PageLayout>
      {/* splash screen */}
      {loading ? (
        <SplashScreen />
      ) : (
        <>
          {/* page content */}
          <ChangePageTitle pageTitle="Projects" />
          {/* product card container */}
          <div
            className="grid grid-cols-2 md:grid-cols-4 w-full min-h-[calc(100vh-96px)] sm:min-h-[calc(100vh-160px)] grid-flow-row gap-2 md:gap-4 px-2 py-4 sm:py-8 text-center">
            {projects?.map((project) => {
              return <Card key={project?.id} item={project} />
            })}
          </div>
        </>
      )}
    </PageLayout>
  )
}

export default Projects
