import React, {useEffect, useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import Slider from "react-slick";

import ChangePageTitle from '../components/change-page-title/ChangeTitle';
import PageLayout from '../components/page-layout';
import getProjectDetail from "../services/get-project"
import SplashScreen from '../components/splash-screen/SplashScreen'
import { ArrowLongLeftIcon } from '@heroicons/react/24/outline'

const ProjectDetail = () => {

  const [project, setProject] = useState()
  const [loading, setLoading] = useState(true) // loading status for splash screen
  const { id } = useParams();

  const getProject = async () => {
    const project = await getProjectDetail(id)
      return project
  }

  useEffect(() => {
    getProject().then(res => {
      setProject(res)
      setLoading(false)
    })
      .catch(err => {
        console.error("Failed to fetch works:", err)
        setLoading(false)
      })
  }, [id])

  // slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1
  };

  return (
    <PageLayout>
      {/* splash screen */}
      {loading ? (
        <SplashScreen />
      ) : (
        <>
      <div className='flex flex-col justify-start items-center text-center min-h-[calc(100vh-96px)] md:min-h-[calc(100vh-160px)] gap-8 py-4 sm:py-8'>
        {/* page title */}
        <ChangePageTitle pageTitle={project?.fields?.name} />
        {/* image container */}
        <div className='relative w-full md:max-w-4xl flex flex-col mb-2'>
          <Link to="/Projects" className="hidden md:flex absolute top-0 -right-20 font-light group text-sm hover:text-primary_red transition-all ease-in-out duration-300">
            <div className="flex flex-col justify-start items-start text-wrap">
              <span>Back</span>
              <span>to</span>
              <span>Projects</span>
              <span className="w-6">
              <ArrowLongLeftIcon className="text-white stroke-1 group-hover:text-primary_red transition-all ease-in-out duration-300"/>
              </span>
            </div>
          </Link>
          <Slider {...settings}>
            {
              project?.fields?.images.map((image, i) => (
              <img key={i} className='object-contain' src={image?.url} alt='product-photo' />
              ))
            }
          </Slider>
        </div>
        {/* text container */}
        <div className='flex flex-col justify-center max-w-[750px] min-w-[300px] mx-12 gap-2 md:mx-0'>
          {/* project name */}
          <div className='flex justify-center items-center text-lg h-12'>
            {project?.fields?.name}
          </div>
          {/* project technic */}
          <div className='flex justify-center items-center text-justify font-light'>
            {project?.fields?.technic}
          </div>
          {/* project measurement */}
          <div className='flex justify-center items-center text-justify font-light'>
            {project?.fields?.measurement}
          </div>
          {/* project date */}
          <div className='flex justify-center items-center text-justify font-light'>
            {project?.fields?.date}
          </div>
        </div>
      </div>
        </>
      )}
    </PageLayout>
  )
} 

export default ProjectDetail