import React from 'react'
import { Link } from 'react-router-dom'

const Card = (item) => {
  let link;

  if(item.item._table.name == "works") {
    link = '/' + 'works/' + item.item.id
  } else {
    link = '/' + 'projects/' + item.item.id
  }

  const image =  (item?.item?.fields?.image[0]?.url)

  return (
    <Link to={link}>
      <div key={item.id} className='flex flex-col justify-end item-center relative h-60 overflow-hidden group rounded-md'>
        <div className='flex-1 w-full h-full overflow-hidden rounded-md'>
          <img className='w-full h-full object-cover group-hover:scale-110 ease-in-out duration-300' src={image} alt=''/>
        </div>
        <div className='text-center p-2 flex-shrink-0 group-hover:text-primary_red font-light '>
          {item?.item?.fields?.name}
        </div>
      </div>
    </Link>
  )
}

export default Card