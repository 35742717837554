export default async function getWork(recordId) {
  const response = await fetch(
    `https://api.airtable.com/v0/appk2oMdlfegHaMUF/works/${recordId}`,
    {
      cache: 'no-store',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' +
          'pat2W4TdQg0JHBFuR.bdbe39ffd64ca630e08491cdc238a15b4fe82e59b88dfc8375aed7d0872e586e',
      },
    },
  ).then((response) => response.json())

  if (response) {
    return response
  }
  throw 'throw'
}
