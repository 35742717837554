import React from 'react'

const Footer = () => {
  return (
    <>
      <div className='w-full flex flex-col justify-center text-center gap-3 my-4 '>
          {/* footer logo */}
          <div className='flex justify-center items-center text-center'>
            <img src="/logomini.png" alt="logo" className='rounded-full w-20 h-20'></img>
          </div>
          {/* follow me links */}
          <div className='flex justify-center items-center'>
            <div className='flex justify-center items-center gap-5 font-light text-sm w-full'>
              <a className='flex-1 px-4 pb-2 pt-3 hover:text-primary_red hover:bg-background_light_red rounded-md ease-in-out duration-300' href='https://www.instagram.com/ipekyucesoy/' target='_blank'>INSTAGRAM</a>
            </div>
          </div>
          {/* copyright text */}
          <div className='flex justify-center gap-2 text-sm py-4 w-full'>
            <span>Copyright © 2024 İPEK YÜCESOY.</span>
            <span className='font-light'>All Rights Reserved.</span>
          </div>
      </div>
    </>
  )
}

export default Footer