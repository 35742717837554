import React from 'react'

import ChangePageTitle from '../components/change-page-title/ChangeTitle'
import PageLayout from '../components/page-layout'

const soloExhibitions = [
  {
    name: 'One Big Family',
    location: 'Grandma Art',
    date: '2024'
  },
];

const groupExhibitions = [
  {
    name: 'Cycle',
    location: 'İstiklal Art Gallery',
    date: '2024'
  },
  {
    name: 'Pop-up Group Exhibition',
    location: 'Büyükdere35 & İnspera Bodrum',
    date: '2024'
  },
  {
    name: 'Vallus',
    location: 'Yer Community',
    date: '2024'
  },
  {
    name: 'Enigmavista',
    location: 'Gallery Bosfor',
    date: '2024'
  },
  {
    name: 'A Place',
    location: 'Yer Community',
    date: '2024'
  },
  {
    name: 'Echoes of Nature',
    location: 'Gallery Bosfor',
    date: '2023'
  },
  {
    name: 'I Am a Bridge Between What I Am and What I Could Be. What Am I?',
    location: 'Elgiz Museum',
    date: '2023'
  },
  {
    name: 'The Butterfly’s Storm',
    location: 'Loft Art',
    date: '2022'
  },
  {
    name: 'The Unseen',
    location: 'Elgiz Museum',
    date: '2022'
  },
  {
    name: 'If a Bird Flies, Only Shadows Remain',
    location: 'Nilüfer Municipality',
    date: '2021'
  },
  {
    name: 'Erdemir Steel and Life Exhibition',
    location: 'Erdemir',
    date: '2021'
  },
  {
    name: 'Healing in Istanbul',
    location: 'Karşı Sanat Çalışmaları',
    date: '2021'
  },
];


const About = () => {
  return (
    <PageLayout>
      <ChangePageTitle pageTitle="About" />
      <div
        className="flex flex-col justify-start items-start min-h-[calc(100vh-96px)] sm:min-h-[calc(100vh-160px)] gap-2 px-7 md:px-20 py-4 sm:py-8">
        {/* page title */}
        <h2 className="text-start font-medium w-full tracking-wide">About</h2>
        {/* content container */}
        <div className="flex flex-col justify-start items-start gap-6 font-light w-full">
          {/* birthday container */}
          <div className="w-full text-justify">
            b. 1993, Bursa
          </div>
          {/* education container */}
          <div className="flex flex-col gap-4 w-full">
            <div className="font-semibold tracking-wide">
              Education
            </div>
            {/* list of educations */}
            <div className="flex flex-col gap-2">
              {/* faculty of fine 1rts */}
              <div className="flex flex-row gap-2">
                <div className="min-w-[80px]">
                  2016-2021
                </div>
                <div>
                  Marmara University, Faculty of Fine Arts, Department of Sculpture
                </div>
              </div>
              {/* faculty of law */}
              <div className="flex flex-row gap-2">
                <div className="min-w-[80px]">
                  2011-2015
                </div>
                <div>
                  Marmara University, Faculty of Law
                </div>
              </div>
            </div>
          </div>
          {/* solo exhibitions container */}
          <div className="flex flex-col gap-4 w-full">
            <div className="font-semibold tracking-wide">
              Solo Exhibitions
            </div>
            {/* list of solo exhibition */}
            <div className="flex flex-col gap-2">
              {
                soloExhibitions.map((groupExhibition, index) => (
                  <div className="flex flex-row gap-2" key={index}>
                    <div className="min-w-[60px]">
                      {groupExhibition.date}
                    </div>
                    <div className="flex flex-col md:flex-row gap-2">
                      <span className="italic">{groupExhibition.name},</span>
                      <span>{groupExhibition.location}</span>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          {/* group exhibitions container */}
          <div className="flex flex-col gap-4 w-full">
            <div className="font-semibold tracking-wide">
              Group Exhibitions
            </div>
            {/* list of group exhibition */}
            <div className="flex flex-col gap-2">
              {
                groupExhibitions.map((groupExhibition, index) => (
                  <div className="flex flex-row gap-2" key={index}>
                    <div className="min-w-[60px]">
                      {groupExhibition.date}
                    </div>
                    <div className="flex flex-col md:flex-row gap-2">
                      <span className="italic">{groupExhibition.name},</span>
                      <span>{groupExhibition.location}</span>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default About