import Airtable, { FieldSet, Records } from 'airtable'

const base = new Airtable({
  apiKey:
    'pat2W4TdQg0JHBFuR.bdbe39ffd64ca630e08491cdc238a15b4fe82e59b88dfc8375aed7d0872e586e',
}).base('appk2oMdlfegHaMUF')
let items

async function getWorks() {
  return new Promise((resolve, reject) => {
    base('works')
      .select({
        // Selecting the first 3 records in Grid view:
        view: 'Grid view',
      })
      .all(function page(err, records) {
        if (err) reject(err)

        if (!records) {
          reject('No records found')

          return
        }

        // save the fetches records in a local variable
        // will be useful later
        items = records

        resolve(records)
      })
  })
}

export default getWorks
