import { BrowserRouter, Routes, Route } from 'react-router-dom'
import '../src/main.css'

//pages
import Home from './pages/Home'
import Works from './pages/Works'
import WorkDetail from './pages/WorkDetail'
import About from './pages/About'
import Contact from './pages/Contact'
import Statement from './pages/Statement'
import Projects from './pages/Projects'

import ProjectDetail from './pages/ProjectDetail'
//components
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Layout from './components/layout'

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Header />
        <Routes>
          <Route path="/works" element={<Works />} />
          <Route path="/works/:id" element={<WorkDetail />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:id" element={<ProjectDetail />} />
          <Route path="/statement" element={<Statement />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </Layout>
    </BrowserRouter>
  )
}

export default App
