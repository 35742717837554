import React from 'react'

//components
import ChangePageTitle from '../components/change-page-title/ChangeTitle'
import PageLayout from '../components/page-layout'

const Contact = () => {
  return (
    <PageLayout>
      <div className='flex flex-col justify-start items-center min-h-[calc(100vh-96px)] md:min-h-[calc(100vh-160px)] px-7 md:px-20 py-4 sm:py-8'>
        {/* change page title component */}
        <ChangePageTitle pageTitle='Contact'/>
        {/* page contents */}
        <div className='flex flex-col gap-5'>
          <div className='flex flex-col gap-2'>
            {/* page title */}
            <h2 className='font-semibold tracking-wide'>Contact</h2>
            {/* introduction */}
            <p className='text-justify font-light' >Thank you for visiting my website! If you would like to collaborate, inquire about my work, or simply reach out, feel free to contact me. I’m always open to discussing creative ideas, projects, or potential partnerships.</p>
          </div>
          {/* e-mail address */}
          <div className="flex flex-col gap-2">
            <h2 className="font-semibold tracking-wide">Email</h2>
            <a className="font-light hover:text-[#B30E19] transition-all ease-in-out duration-300" href="mailto:ipekyucesoy1@gmail.com">ipekyucesoy1@gmail.com</a>
          </div>
          {/* links from ipek yücesoy */}
          <div className='flex flex-col gap-4 mt-10'>
            {/* links title */}
            <h2 className='font-semibold tracking-wide'>Follow me on</h2>
            {/* social media */}
            <div className='flex flex-col gap-4 justify-center items-start'>
              <a
                className='flex justify-center items-center text-center gap-2 h-8 group transition-all ease-in-out duration-300'
                href='https://www.instagram.com/ipekyucesoy/' target='_blank'>
                <div className='h-6 group-hover:text-[#B30E19] transition-all ease-in-out duration-300'>
                  <ion-icon name="logo-instagram"></ion-icon>
                </div>
                {/*<p className='text-sm pt-1 font-normal group-hover:text-[#46726D] transition-all ease-in-out duration-300'>INSTAGRAM</p>*/}
              </a>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default Contact