import React from 'react'

import ChangePageTitle from '../components/change-page-title/ChangeTitle'
import PageLayout from '../components/page-layout'

const Statement = () => {
  return (
    <PageLayout>
      <ChangePageTitle pageTitle="Statement"/>
      <div className='flex flex-col justify-start items-start min-h-[calc(100vh-96px)] sm:min-h-[calc(100vh-160px)] gap-6 px-7 md:px-20 py-4 sm:py-8'>
        {/* page title */}
        <h2 className='text-start font-medium text-lg w-full tracking-wide'>Statement</h2>
        {/* content container */}
        <div className='flex flex-col justify-start items-start gap-7 md:gap-4 font-light w-full'>
          <div className='w-full text-justify'>
            The search for safety, belonging, and a secure space lies at the core of my artistic practice. This search
            takes shape as the need to find a place and a community within the uncertainty—both as a geographical
            location and a felt state. In my work, the topographies of space and images of community emerge as central
            elements expressing this pursuit.
          </div>
          <div className='w-full text-justify'>
            The representation of community, family, and a safe space holds a fundamental place in my creations. By
            stylizing human, animal, and nature forms, I connect the viewer to familiar imagery while simultaneously
            creating an uncanny atmosphere through a childlike and stylized narrative. This combination draws the viewer
            into a world that feels recognizable yet cannot be fully defined.
          </div>
          <div className='w-full text-justify'>
            A monochrome and minimalist approach is a significant aspect of my visual language. The unity of color
            within the community lifts my works beyond everyday life, forming an abstract representation of a secure
            space. Through organic forms and vibrant colors, my pieces evoke the warmth of a family or community.
            However, the dimensionless and monochromatic nature of these figures positions the viewer outside the safe
            space, creating a sense of distance.
          </div>
          <div className='w-full text-justify'>
            My works question the forms of togetherness and belonging, exploring where a safe space resides, how it
            takes shape, and how individuals are included or excluded from it. Through the interplay of color and form,
            I visualize the longing for safety and how this longing intertwines with an unsettling atmosphere.
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default Statement