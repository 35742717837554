import React, { useState, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'

const headerNav = [
  {
    displayName: 'Works',
    path: '/works'
  },
  {
    displayName: 'Projects',
    path: '/projects'
  },
  {
    displayName: 'Statement',
    path: '/statement'
  },
  {
    displayName: 'About',
    path: '/about'
  },
  {
    displayName: 'Contact',
    path: '/contact'
  },
];

const Header = () => {
  const location = useLocation();
  const headerRef = useRef(null)

  // hamburger menu state
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header ref={headerRef}>
      {/* overlay backdrop blur */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-white bg-opacity-50 backdrop-blur-sm z-10 transition-all duration-300"
          onClick={() => setIsMenuOpen(false)}
        ></div>
      )}

      <div className='relative flex flex-row flex-wrap w-full h-24 md:h-40 justify-center item-center z-10 bg-white'>
        {/* brand name */}
        <div className='flex justify-center items-center w-full text-center text-2xl py-0 md:py-8 font-bold'>
          <Link to="/">ipek yücesoy</Link>
        </div>

        {/* hamburger menu button (mobile) */}
        <button
          className="block md:hidden absolute top-5 right-5 space-y-1 group"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <span className="flex justify-end">
            <span
              className={`block h-0.5 transition-transform ease-in-out duration-300 rounded-md ${
                isMenuOpen ? 'rotate-45 translate-y-1.5 bg-primary_red w-5' : 'bg-black w-4'
              }`}
            ></span>
          </span>
          <span
            className={`block h-0.5 w-5 transition-opacity ease-in-out duration-300 rounded-md ${
              isMenuOpen ? 'opacity-0' : 'bg-black'
            }`}
          ></span>
          <span className="flex justify-end">
            <span
              className={`block h-0.5 w-5 transition-transform ease-in-out duration-300 rounded-md ${
                isMenuOpen ? '-rotate-45 -translate-y-1.5 bg-primary_red' : 'bg-black w-3.5'
              }`}
            ></span>
          </span>
        </button>

        {/* nav menu (mobile) */}
        <nav
          className={`${
            isMenuOpen ? 'flex' : 'hidden'
          } absolute top-full left-0 w-full bg-white border-b border-primary_red py-3 shadow-md`}
        >
          <ul className="w-full flex flex-col md:flex-row gap-4 text-base font-light mb-4">
            {headerNav.map((menu, index) => (
              <li key={index} className="w-full flex">
                <Link
                  to={menu.path}
                  className={`relative w-full flex justify-end px-5 pb-3 pt-4 hover:text-primary_red hover:bg-background_light_red transition-all ease-in-out duration-300 uppercase ${
                    location.pathname === menu.path ? 'text-primary_red' : ''
                  }`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  <span className="leading-3">{menu.displayName}</span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        {/* nav bar (web) */}
        <div className='w-full hidden md:block'>
          {/* nav bar map section */}
          <ul className='flex flex-row flex-wrap gap-3 md:gap-5 justify-center text-sm md:text-base font-light'>
            {
              headerNav.map((menu, index) => (
                <li key={index} >
                  <Link to={menu.path} className={`relative px-4 pb-2 pt-3 group hover:text-primary_red hover:bg-[#FCD9DC] rounded-md rounded-tr-none transition-all ease-in-out duration-300 uppercase ${
                    location.pathname === menu.path
                      ? 'text-primary_red'
                      : ''
                  }`}>
                    <span className="leading-3">
                      {menu.displayName}
                    </span>
                    <div
                      className="absolute flex flex-col  items-end right-0 -top-1.5 w-[95%] h-1.5">
                      <div className="right-0 h-1 w-[30%] group-hover:bg-background_light_red rounded-md rounded-b-none transition-all ease-in-out duration-300">
                      </div>
                      <div className="h-0.5 w-full group-hover:bg-background_light_red rounded-md rounded-tr-none rounded-b-none transition-all ease-in-out duration-300">
                      </div>
                    </div>
                  </Link>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header 